$(document).ready(function () {
	initSwiper();
	checkCurrentBg();
});

var App = App || {};

App.bps = {};
App.bps.mobileW = 768;
App.bps.mobileH = 640;

function checkWindow() {
	//check window width (scrollbar included)
	var e = window,
		a = 'inner';
	if (!('innerWidth' in window)) {
		a = 'client';
		e = document.documentElement || document.body;
	}
	if (e[a + 'Width'] < App.bps.mobileW || e[a + 'Height'] < App.bps.mobileH) {
		// console.log('mobile');
		return false;
	} else {
		// console.log('desktop');
		return true;
	}
}

function checkCurrentBg() {
	if ($('.swiper-slide-active').hasClass('main-slider__slide--bg')) {
		$('main').addClass('main--inverse');

	} else {
		$('main').removeClass('main--inverse');
	}
}

function setMinHeight() {
	if ($('html').hasClass('bx-ie')) {
		let $arrSlides = $('.main-slider').find('.main-slider__content'),
			heightSreen = $(window).outerHeight();

		$arrSlides.css({
			'height': 'auto'
		})

		$arrSlides.each(function (index, el) {
			if ($(el).outerHeight() <= heightSreen) {
				$(el).css({
					'height': heightSreen +'px'
				})
			} else {
				$(el).css({
					'height': 'auto'
				})
			}
		});
	}
}

function initSwiper() {
	var $mainSlider = $('.main-slider');

	if ($mainSlider.length) {
		var isMobile = window.innerWidth <= 768;
		var isTargetPage = window.location.pathname.includes("/company/about/");

		if (isMobile && isTargetPage) {
			return;
		}

		var mainSlider;

		(function () {
			var initSwiper = function initSwiper() {
				var desktop = checkWindow();
				// console.log(desktop);

				if ((desktop || $('body').hasClass('body--fullscreen')) && mainSlider == undefined) {
					if (!$('.js-btn-slideNext').length || !$('.main-slider__nav').length) {
						$('.layout__main').append('<button class="swiper-next-btn js-btn-slideNext"></button>');
						$('.main-slider__controls').append('<button class="main-slider__nav main-slider__nav--bottom"></button><button class="main-slider__nav main-slider__nav--top"></button>');
					}
					var $sliderArrow = $('.js-btn-slideNext');
					let enableHash = ($('.main-slider').hasClass('js-hash-slider')) ? true : false;

					mainSlider = new Swiper('.main-slider', {
						direction: 'vertical',
						speed: 400,
						mousewheelControl: true,
						pagination: '.main-slider__pagination',
						paginationClickable: true,
						simulateTouch: false,
						hashnav: enableHash,
						hashnavWatchState: enableHash,
						replaceState: enableHash,
						nextButton: '.main-slider__nav--bottom',
						prevButton: '.main-slider__nav--top',
						onInit: function () {
							let $imgArray = $mainSlider.find('.main-slider__img');
							$imgArray.each(function (index, el) {
								let $parent = $(el).closest('.main-slider__slide--bg');
								$parent.css({
									'background-image': `url(${$(el).attr('src')})`
								});
							});


							setMinHeight();
							checkCurrentBg();
							$('.swiper-slide').mCustomScrollbar({
								scrollInertia: 500
							});
							setTimeout(objectFitPolyfill, 1000);
						},
						onImagesReady: function () {
							setTimeout(objectFitPolyfill, 1000);
							setMinHeight();
						},
						onSlideChangeStart: function (swiper) {
							// if ($('body').hasClass('body--fullscreen') && $('.breadcrumbs--fullscreen').length) {
							// 	$('.breadcrumbs--fullscreen').hide();
							// }
							if (swiper.isEnd) {
								$sliderArrow.velocity('stop').velocity('fadeOut', 100);
							} else if ($sliderArrow.is(':hidden')) {
								$sliderArrow.velocity('stop').velocity('fadeIn', 100);
							}
							checkCurrentBg();
						},
						onSlideChangeEnd: function () {
							// if ($('body').hasClass('body--fullscreen') && $('.breadcrumbs--fullscreen').length) {
							// 	$('.breadcrumbs--fullscreen').show();
							// }
						}
					});
				} else if (!$('body').hasClass('body--fullscreen') && !desktop && mainSlider !== undefined) {
					$('.layout__main').find('.js-btn-slideNext').remove();
					$('.main-slider__controls').find('.main-slider__nav--bottom').remove();
					$('.main-slider__controls').find('.main-slider__nav--top').remove();
					mainSlider.destroy(true, true);
					mainSlider = undefined;
				}
			};
			mainSlider = undefined;

			initSwiper();
			$(window).on('resize', function () {
				var isMobile = window.innerWidth <= 768;
				var isTargetPage = window.location.pathname.includes("/company/about/");

				if (isMobile && isTargetPage) {
					return;
				}
				initSwiper();
				setMinHeight();
			});
		}());
	}


	$('.js-btn-slideNext').on('click', function (event) {
		event.preventDefault();
		mainSlider.slideNext();
	});
}

export {setMinHeight};